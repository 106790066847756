<template>
  <section>
    <b-row class="match-height">
      <b-col
        md="6"
      >
        <b-card>
          <b-tabs fill>
            <b-tab
              active
              title="Planning"
            >
              <b-card-text>Geen planning</b-card-text>
            </b-tab>
            <b-tab title="Activiteiten">
              <b-card-text>Geen activiteiten</b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
      <b-col
        md="3"
      >
        <b-card
          title="Snelkoppelingen"
          class="shortcuts"
        >
          <div class="d-flex justify-content-between">
            <b-link
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :to="{ name: 'calendar'}"
              class="btn btn-icon rounded-circle btn-info text-center"
            >
              <span>
                <feather-icon
                  icon="CalendarIcon"
                  class="font-medium-5"
                />
              </span>
              <small class="d-block">Agenda</small>
            </b-link>
            <b-link
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :to="{ name: 'register'}"
              class="btn btn-icon rounded-circle btn-info text-center"
            >
              <span>
                <feather-icon
                  icon="ShoppingCartIcon"
                  class="font-medium-5"
                />
              </span>
              <small class="d-block">Kassa</small>
            </b-link>
            <b-link
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :to="{ name: 'customers'}"
              class="btn btn-icon rounded-circle btn-info text-center"
            >
              <span>
                <feather-icon
                  icon="UsersIcon"
                  class="font-medium-5"
                />
              </span>
              <small class="d-block">Klanten</small>
            </b-link>
          </div>
        </b-card>
        <b-card title="Medewerkers">
          <div
            class="d-flex justify-content-start align-items-center mt-1"
          >
            <b-avatar
              :src="require('@/assets/images/portrait/small/avatar-s-6.jpg')"
              class="mr-50"
              size="40"
            />
            <div class="user-page-info">
              <h6 class="mb-0">
                Rachel Manusiwa
              </h6>
              <small class="text-muted">3 afspraken</small>
            </div>
            <div class="ml-auto text-right">
              <small class="text-muted">10:00 - 11:15</small><br>
              <small class="text-muted">11:45 - 13:30</small><br>
              <small class="text-muted">14:00 - 14:30</small>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col
        md="3"
      >
        <b-card title="Notities">
          <b-card-text>Geen notities</b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="6"
      >
        <b-card title="Klanten" />
      </b-col>
      <b-col
        md="6"
      >
        <b-card title="Bezetting" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BTabs, BTab, BCard, BCardText, BLink, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BCard,
    BCardText,
    BLink,
    BAvatar,
  },
  directives: {
    Ripple,
  },
}
</script>

<style>
.shortcuts .btn {
  display: inline-block;
  width: 80px;
  height: 80px;
  line-height: 28px;
}
</style>
